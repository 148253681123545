<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">
        Stock Opname - {{ id > 0 ? 'Edit' : 'Tambah' }}
      </span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">
          / Inventory / Stock Opname / {{ id > 0 ? 'Edit' : 'Tambah' }}
        </span>
      </div>
    </div>
    <form @submit.prevent="save(!v$.$invalid)">
      <!-- {{ detailItem }} <br/>
      {{ csv_item }} -->
      <div class="card bg-white">
        <div class="flex justify-content-end mb-2">
          <Button
            :loading="isLoadingSave"
            icon="pi pi-save"
            class="mr-2"
            :label="id > 0 ? 'Update' : 'Simpan'"
            :disabled="saved || old_status_value === 'done'"
            type="submit"
          />
          <Button
            type="button"
            icon="pi pi-file-excel"
            label="Download CSV"
            class="p-button-green mr-2"
            :disabled="saved || old_status_value === 'done'"
            @click="generateCSV"
          />
          <!-- <download-excel :data="json_data" type="xlsx" name="opname.xlsx" stringifyLongNum=true>
            <Button
            type="button"
            icon="pi pi-file-excel"
            label="Download Excel"
            class="p-button-green mr-2"
          />
          </download-excel> -->
          <Button
            type="button"
            icon="pi pi-file-import"
            label="Upload"
            class="p-button-green mr-2"
            :disabled="saved || old_status_value === 'done'"
            @click="uploadCSV"
          />
          <Button
            v-if="old_status_value !== 'done'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Item"
            @click="onAddData"
          />
          <!-- <Button
            v-if="old_status_value !== 'done'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Kategori"
            @click="onAddCategory"
          /> -->
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'StockOpname' })"
          />
        </div>
        <div class="grid">
          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label class="w-full">Tgl. Opname</label>
                <Calendar
                  v-model="form.sop_at"
                  dateFormat="dd/mm/yy"
                  class="w-full mr-2"
                  :class="{ 'p-invalid': v$.form.sop_at.$invalid && submitted }"
                  input-class="w-full"
                  :disabled="blnImported"
                />
                <small
                  v-if="
                    (v$.form.sop_at.$invalid && submitted) ||
                    v$.form.sop_at.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.sop_at.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-4">
                <label>No Opname</label>
                <InputText
                  v-model="form.sop_no"
                  class="w-full"
                  input-class="w-full"
                  maxlength="50"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-4"></div>
              <div class="field col-12 md:col-4">
                <label>Lokasi/Gudang</label>
                <InputText
                  v-model="form.whouse"
                  class="w-full"
                  input-class="w-full"
                  maxlength="100"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Dicek/Dihitung oleh</label>
                <InputText
                  v-model="form.assignee"
                  class="w-full"
                  input-class="w-full"
                  maxlength="255"
                />
              </div>
              <div class="field col-12 md:col-4"></div>
              <div class="field col-12 md:col-4">
                <label>Deskripsi</label>
                <InputText
                  v-model="form.description"
                  class="w-full"
                  input-class="w-full"
                  maxlength="100"
                />
              </div>
              <div class="field col-12 md:col-4">
                <label>Status</label>
                <Dropdown
                  v-model="form.status"
                  :options="list_status"
                  :disabled="!id || old_status_value === 'done'"
                  optionValue="value"
                  optionLabel="label"
                  optionDisabled="disabled"
                  class="w-full"
                  input-class="w-full"
                />
                <small v-if="item_id == 0 && submitted" class="p-error"
                  >Item harus dipilih.</small
                >
              </div>
              <div
                v-if="form.id > 0 && form.status == 'done'"
                class="field col-12 md:col-4"
              >
                <label>Disetujui Oleh</label>
                <InputText
                  v-model="form.inspector"
                  class="w-full"
                  input-class="w-full"
                  maxlength="100"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <fx-table
            :items="form.details"
            :total="form.details.length"
            :loading="isLoading"
            :paginator="false"
            row-class="rowClass" 
            showGridlines
            showRowClass
          >
            <template #columns>
              <!-- <Column field="row_id" header="row_id" width="5%" /> -->
              <!-- <Column field="id" header="id" width="5%" /> -->
              <Column
                field="item_sku"
                header="SKU"
                style="width: 10%"
              />
              <Column field="item_name" header="Nama Item" style="width: 30%" />
              <Column
                field="unit_s"
                header="Satuan"
                style="width: 19%; justify-content: flex-end"
              />
              <!-- <Column
                field="ubox"
                header="ISI"
                style="width: 11%; justify-content: flex-end"
              /> -->
              <Column
                field="qty_noted"
                header="QTY Tercatat"
                style="width: 13%; justify-content: flex-end"
              >
                <template #body="slotProps">
                  {{ slotProps.data.qty_noted }}
                </template>
              </Column>
              <Column
                field="qty"
                header="qty Hitung"
                style="width: 13%; justify-content: flex-end"
              >
                <template #body="slotProps">
                  {{ slotProps.data.qty }}
                </template>
              </Column>
              <Column style="flex-grow: 1; justify-content: flex-end">
                <template #body="{ data }">
                  <Button
                    type="button"
                    icon="pi pi-pencil"
                    class="
                      p-button-outlined p-button-secondary p-button-text
                      mr-2
                    "
                    :disabled="saved || old_status_value === 'done'"
                    @click="onEditData(data)"
                  />
                  <Button
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-outlined p-button-danger p-button-text"
                    :disabled="saved || old_status_value === 'done'"
                    @click="onConfirmDeletion(data)"
                  />
                </template>
              </Column>
            </template>
          </fx-table>
        </div>
      </div>
    </form>
    <Dialog
      :header="
        formDetail && formDetail.item_id > 0
          ? 'Edit Item Stock Opname'
          : 'Tambah Item Stock Opname'
      "
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :maximizable="true"
    >
      <form-opname
        :item="formDetail"
        :loading="isLoadingSave"
        :adjustment="Opname"
        :editMode="editMode"
        :opnameMode="true"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Tambah Item Kategori"
      v-model:visible="dialogCategory"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <label>Kategori</label>
        <Dropdown
          v-model="ctg_id"
          :filter="true"
          :options="list_kategori"
          class="w-full"
          input-class="w-full"
          optionValue="id"
          optionLabel="name"
          placeholder="Pilih Kategori"
        />
      </div>
      <template #footer>
        <Button
          :loading="loading"
          label="Tambahkan"
          icon="pi pi-check"
          type="submit"
          @click="onLoadCategory(ctg_id)"
        />
        <Button
          label="Cancel"
          @click="onCancel()"
          class="p-button p-button-primary"
        />
      </template>
    </Dialog>
    <Dialog
      header="Hapus Stock Opname"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item Stock Opname <strong>{{ formDetail.item_name }}</strong> akan
          dihapus. Proses?
        </span>
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemAdjustment"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      header="Import Data CSV"
      v-model:visible="dialogImport"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <input type="file" id="input" ref="doc" @change="readFile()" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv" />
        <div class="card">
          <fx-table
            :items="csv_itemaddnew"
            :total="csv_itemaddnew.length"
            :loading="isLoading"
            :paginator="false"
            showGridlines
          >
            <template #columns>              
              <Column field="sku" header="SKU" width="100" />
              <Column field="name" header="Nama Item" width="200" />
              <Column
                field="qty_inv"
                header="Qty Tercatat "
                width="100"
              />
              <Column field="qty_op" header="Qty Hitung" width="100" />
            </template>
          </fx-table>
        </div>
      </div>
      <template #footer>
        <Button
          label="OK"
          @click="onLoadCSV()"
          class="p-button p-button-primary"
        />
        <Button
          label="Cancel"
          @click="onCancel()"
          class="p-button p-button-primary"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import StockOpnameService from '@/services/StockOpnameService'
import FormOpname from '@/components/inventory/FormOpname'
import FxTable from '@/components/FxTable'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { FilterMatchMode } from 'primevue/api'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { useAuthStore } from '@/store/auth'
import { formatDate } from '@/helpers/index'
import dayjs from 'dayjs'
import KategoriService from '@/services/KategoriService'
import readXlsxFile from 'read-excel-file'

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'Opname',
  components: {
    FormOpname,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      submitted: false,
      saved: false,
      file: null,
      content: null,
      str: '',
      stockOpnameService: null,
      ctg_id: 0,
      kategoriService: null,
      dialog: false,
      dialogHapus: false,
      dialogImport: false,
      dialogCategory: false,
      isLoading: false,
      isLoadingSave: false,
      editMode: false,
      old_status_value: 'draft',
      csv_item: [],
      csv_itemaddnew: [],
      blnImported: false,
      items: [],
      row_id: 0,
      breakLines: '-',
      detailItem: null,
      form: {
        sop_at: new Date(),
        sop_type: 'opname',
        inspector: '',
        assignee: '',
        whouse: '',
        description: '',
        status: 'draft',
        details: [],
      },
      formDetail: {
        row_id: 0,
        sop_at: '',
        item_id: 0,
        item_name: '',
        item_sku: '',
        qty_noted: 0,
        unit_b: 0,
        unit_s: 0,
        ubox: 0,
        qty_b: 0,
        qty_s: 0,
        qty: 0,
      },
      isCheck: false,
      json_data: [
              {
                  name: "00000001",
                  city: "New York",
              },
              {
                  name: "00000002",
                  city: "Athens",
              },
          ],
      listDeleted: [],
      Opname: [],
      containModesOption: [
        { label: 'Contains', value: FilterMatchMode.CONTAINS },
      ],
      equalModesOption: [{ label: 'Equals', value: FilterMatchMode.EQUALS }],
    }
  },
  computed: {
    list_status() {
      const list = [
        { value: 'draft', label: 'Draft', level: 0 },
        { value: 'done', label: 'Done', level: 1 },
      ]
      let old_status_level = 0
      if (this.old_status_value) {
        const status = list.find((e) => e.value === this.old_status_value)
        if (status) old_status_level = status.level
      }
      return list.map((status) => ({
        ...status,
        disabled: old_status_level > status.level,
      }))
    },
  },
  created() {
    this.stockOpnameService = new StockOpnameService()
    this.kategoriService = new KategoriService()
  },
  async mounted() {
    const auth = useAuthStore()
    this.form.inspector = auth.user.name

    if (this.id > 0) {
      this.loadData()
    }

    this.kategoriService
      .getBy('?sort=name')
      .then((res) => {
        this.list_kategori = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Kategori', this)
      })
  },
  methods: {
    setDateJthTempo() {
      const idx = this.list_items.findIndex(
        (item) => item.id === this.form.term_id
      )
      if (idx != -1) {
        const sop_at = dayjs(this.form.sop_at)
        const due_at = sop_at.add(this.list_term[idx].term, 'day')
        this.form.due_at = new Date(due_at)
      }
    },
    formatDate(value) {
      return formatDate(value, true)
    },
    totalDetail(detail) {
      const item =
        this.list_items.filter((item) => item.id === detail.item_id).length > 0
          ? this.list_items.filter((item) => item.id === detail.item_id)[0]
          : {}

      // perhitungan berdasarkan PER
      let qty_b = detail['qty_b']
      let qty_s = detail['qty_s']
      let b, qty, bruto

      if (detail['cost_per'] === detail['unit_b']) {
        b = detail['ubox'] * qty_b
        qty = b + qty_s
        bruto = (detail['cost'] / detail['ubox']) * qty
      } else {
        qty = qty_b + qty_s
        bruto = detail['cost'] * qty
      }

      // total
      let dpp = bruto

      // disc ext
      let disc_ext = detail['disc_ext']
      if (disc_ext >= 1.0 && disc_ext <= 100.0) {
        let disc_ext_value = dpp * (disc_ext / 100.0)
        dpp -= Math.round(disc_ext_value * 100) / 100
      } else if (disc_ext > 100.0) {
        let disc_ext_value = disc_ext
        dpp -= Math.round(disc_ext_value * 100) / 100
      }

      // // ppn
      // let ppn = Math.round(dpp * (detail.tax / 100.0) * 100) / 100

      // // total
      // let total = dpp + ppn
      let total = dpp

      // price
      let price = dpp / qty / (1 - item.std_mrg / 100.0)
      price = Math.round((price * (1 + (this.form.tax / 100.0) * 100)) / 100)

      return {
        qty: qty,
        bruto: bruto,
        dpp: dpp,
        // ppn: ppn,
        total: total,
        price: price,
      }
    },
    uploadCSV() {
      this.dialogImport = true
    },
    generateCSV() {
      // const data = [{ waktu: 'yyyy-MM-dd hh:mm:ss', sku: '', qty: 0 }]
      let data = []
      this.form.details.forEach((item) => {
        let obj = {
          sku: "#"+item.item_sku,
          name: item.item_name,
          qty_inv: item.qty_noted,
          qty_op: item.qty,
        }
        data.push(obj)
      })

      if (data.length === 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Stock Opname',
          detail: 'Detail stock opname masih kosong.',
          life: 3000,
        })
        return
      }

      const csvContent = this.convertToCSV(data)
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'so_template.csv')
      link.click()
    },
    convertToCSV(data) {
      const headers = Object.keys(data[0])
      const rows = data.map((obj) => headers.map((header) => obj[header]))
      const headerRow = headers.join(';')
      const csvRows = [headerRow, ...rows.map((row) => row.join(';'))]
      return csvRows.join('\n')
    },
    onCancel() {
      this.dialogImport = false
      this.dialogCategory = false
      // this.csv_item = []
      this.csv_itemaddnew = []
      this.content = {}
      this.ctg_id = 0
    },
    readFile() {
      // this.csv_item = []
      this.file = this.$refs.doc.files[0]      

      const reader = new FileReader()
      if (this.file.name.includes('.csv')) {
        reader.onload = (res) => {
          this.content = res.target.result
          this.breakLines = this.content.includes('\r') === true ? 'r' : 'n'
          var lines
          if (this.breakLines === 'r') {
            lines = this.content.split('\r\n')
          } else {
            lines = this.content.split('\n')
          }
          var delimitter = this.content.includes(';') === true ? ';' : ','
          var headers = lines[0].split(delimitter)

          for (var i = 1; i < lines.length; i++) {
            var obj = {}
            var currentline = lines[i].split(delimitter)
            // this.row_id += 1
            // obj["row_id"] = this.row_id.toString()
            for (var j = 0; j < headers.length; j++) {
              if (j === 0 && currentline[j].charAt(0) === "#") {
              obj[headers[j]] = currentline[j].substring(1)
              } else {
                obj[headers[j]] = currentline[j]
              }
            }
            if (currentline[0] !== '') {
              // this.csv_item.push(obj)
              this.csv_itemaddnew.push(obj)
            }
          }
          // this.str = JSON.stringify(this.csv_item)
          // this.str = this.csv_item
          console.log('data', this.csv_item)
        }
        reader.readAsText(this.file)
      } else if (this.file.name.includes('.xls')) {
          readXlsxFile(this.file).then((rows) => {
          var headers = []
          for (var h = 0; h < rows[0].length; h++) {
            headers.push(rows[0][h])
          }
          var a_row = this.row_id;
          for (var i = 1; i < rows.length; i++) {
            var obj = {}
            a_row += 1
            obj["row_id"] = a_row.toString()
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = rows[i][j].toString()
            }
            // this.csv_item.push(obj)
            this.csv_itemaddnew.push(obj)
          }
          // this.str = JSON.stringify(this.csv_item)
        })
      } else {
        this.content = 'check the console for file output'
        reader.onload = (res) => {
          console.log(res.target.result)
        }
        reader.onerror = (err) => console.log(err)
        reader.readAsText(this.file)
      }
    },
    onLoadCSV() {
      this.isLoading = true
      this.csv_itemaddnew.forEach((item) => {

        
            this.row_id += 1
            item.row_id = this.row_id.toString()
            this.csv_item.push(item)
          })
      this.str = JSON.stringify(this.csv_item)
      const csv = {
        json: this.str,
        id: this.form.id
      }
      this.stockOpnameService
        .loadCSVItem(csv)
        .then((res) => {
            this.form.details = res.data.data
            if (this.form.details.length > 0) {
            this.form.sop_at = new Date(this.form.details[0].sop_at)
            }
          this.blnImported = true
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data CSV Item', this)
          console.log(err.$response)
          this.$toast.add({
            severity: 'error',
            summary: 'Stock Opname',
            detail: 'Format file csv tidak sesuai.',
            life: 3000,
          })
        })
        .finally(() => (this.isLoading = false))
      this.onCancel()
    },
    onLoadCategory(ctg_id) {
      if (this.ctg_id === 0) {
        return
      }
      this.isLoading = true
      this.stockOpnameService
        .loadCategoryItem(ctg_id)
        .then((res) => {
          this.form.details = res.data.data
          if (this.form.details.length > 0) {
            this.form.sop_at = new Date(this.form.details[0].sop_at)
          }
          this.blnImported = true
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Category Item', this)
        })
        .finally(() => (this.isLoading = false))
      this.onCancel()
    },
    save(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }
      
      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const sop_at = this.form.sop_at
        ? {
            sop_at: dayjs(this.form.sop_at).format('YYYY-MM-DD'),
          }
        : {}
      const form = {
        ...sop_at,
        status: this.form.status,
        sop_no: this.form.sop_no,
        sop_type: 'opname',
        inspector: this.form.inspector,
        assignee: this.form.assignee,
        whouse: this.form.whouse,
        description: this.form.description,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            item_id: item.item_id,
            qty_noted: item.qty_noted,
            qty_b: item.qty_b,
            qty_s: item.qty_s,
          }
        }),
        ...deleted,
      }
      if (!this.id) {
        this.isLoadingSave = true
        this.stockOpnameService
          .add(form)
          .then((res) => {
            if (res.data.status === 1) {
              this.$toast.add({
                severity: 'success',
                summary: 'Stock Opname',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })

              this.$router.push({ name: 'StockOpname' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Stock Opname', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.stockOpnameService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 1) {
              this.loadData()
              this.$toast.add({
                severity: 'success',
                summary: 'Stock Opname',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
            }
            if (form.status == 'done') {
              this.saved = true
            }
            this.$router.push({ name: 'StockOpname' })
          })
          .catch((err) => {
            errorHandler(err, 'Stock Opname', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    rowClass(data) {
      if (data.mark === 3) {
        return 'bg-green-100'
      }
      if (data.mark === 2) {
        return 'bg-blue-100'
      }
      if (data.mark === 1) {
        return 'bg-pink-100'
      }
      if (data.mark === 0) {
        return 'default-row'
      }
    },
    loadData() {
      this.isLoading = true
      this.stockOpnameService
        .getById(this.id)
        .then((res) => {
          this.form = res.data.data
          this.old_status_value = this.form.status
          this.form.sop_at = this.form.sop_at
            ? new Date(this.form.sop_at)
            : new Date()
          this.row_id = 0
          
          this.form.details.forEach((item) => {
            this.row_id += 1
            item.row_id = this.row_id
            var obj = {}

            obj["row_id"] = item.row_id.toString()
            obj["sku"] = item.item_sku
            obj["name"] = item.item_name
            obj["qty_inv"] = item.qty_noted.toString()
            obj["qty_op"] = item.qty.toString()
            this.csv_item.push(obj)

          })

          this.str = JSON.stringify(this.csv_item)

          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Stock Opname', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      this.editMode = true
      data.sop_at = new Date(data.sop_at)
      this.formDetail = Object.assign({}, data)
      this.dialog = true
    },
    onAddData() {
      this.editMode = false
      this.formDetail = {
        row_id: 0,
        sop_at: new Date(),
        item_id: '',
        unit_b: '',
        unit_s: '',
        cost_per: '',
        ubox: '',
        qty_b: 1,
        qty_s: 0,
        qty: 0,
        cost: 0,
        disc_reg: 0,
        disc_ext: 0,
      }
      this.dialog = true
    },
    onAddCategory() {
      this.dialogCategory = true
    },
    async onSaveData(data) {
      const detailItem = Object.assign({}, data)
    
      const idx = this.form.details.findIndex(
        (item) => item.row_id === detailItem.row_id
      )
      
      if (this.editMode) {
        Object.assign(this.form.details[idx], { ...detailItem })

        const ix = this.csv_item.findIndex(
          (item) => item.row_id === detailItem.row_id.toString()
        )
        if(ix !== -1) {
          this.csv_item[ix].qty_op = detailItem.qty.toString()
        }        

        this.$toast.add({
            severity: 'success',
            summary: 'Stock Opname',
            detail: 'Data Item berhasil diubah.',
            life: 3000,
          })
        this.dialog = false
      } else {
        
        this.row_id += 1

        const index = this.form.details.findIndex(
          (el) => el.item_id === detailItem.item_id
        )
        
        if (index !== -1) {
          this.form.details[index].mark = 1
          this.form.details[index].qty_s = detailItem.qty_s.toString()
          this.form.details[index].qty = detailItem.qty_s.toString()

          this.$toast.add({
            severity: 'success',
            summary: 'Data Item Stock Opname',
            detail: 'Data Item berhasil diubah.',
            life: 3000,
          })
        } else {                  
          detailItem.row_id = this.row_id
          this.form.details.push(detailItem)
          this.$toast.add({
            severity: 'success',
            summary: 'Stock Opname',
            detail: 'Data Item berhasil ditambahkan.',
            life: 3000,
          })
        }

        var obj = {}
        obj["row_id"] = this.row_id.toString()
        obj["sku"] = detailItem.item_sku
        obj["name"] = detailItem.item_name
        obj["qty_inv"] = detailItem.qty_noted.toString()
        obj["qty_op"] = detailItem.qty.toString()
        this.csv_item.push(obj)
        this.str = JSON.stringify(this.csv_item)
          
        this.dialog = false

      }
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemAdjustment() {
      const index = this.form.details.findIndex(
        (el) => el.item_id === this.formDetail.item_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Stock Opname',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.onAddData()
      }
    },
    /* onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col))
    }, */
  },
  validations() {
    return {
      form: {
        sop_at: {
          required: helpers.withMessage('Tanggal harus diisi.', required),
        },
      },
    }
  },
}
</script>
